import balkanbet from './balkanbet';
import lutrijabosnaihercegovina from './lutrijabosnaihercegovina';
import simacommunication from './simacommunication';
import topbet from './topbet';
import winner from './winner';
import powerbet from './powerbet';
import greenlotto from './greenlotto';
import stanleybet from './stanleybet';
import bugbet from './bugbet';
import prvafirma from './prvafirma';
import maxbet from './maxbet';
import expo from './companyexpo';
import betmen from './betmen';
import grandslotbet from './grandslotbet';
import napoleon from './napoleon';
import formulabih from './formulabih';
import reisbet from './reisbet';

export default {
  balkanbet,
  lutrijabosnaihercegovina,
  lutrijabihde: lutrijabosnaihercegovina,
  simacommunication,
  topbet,
  winner,
  powerbet,
  greenlotto,
  greenlottoagent: greenlotto,
  stanleybetint: stanleybet,
  stanleybet,
  stanleybetro2: stanleybet,
  bugbet,
  prvafirma,
  maxbet,
  maxbetro: maxbet,
  maxbetromania: maxbet,
  expo,
  betmen,
  grandslotbet,
  napoleon,
  formulabih,
  formula: formulabih,
  formulaseven: formulabih,
  reisbet,
  efbetserbia: topbet,
  efbet: topbet,
};

import Gateway from '@nsftx/seven-gravity-gateway';
import { serverEnv, appConfig, activeEnv } from '../../config';
import { initGA4 } from './gAnalytics';
import { attachGoogleTagManager } from './gTag';
import socket from './socket';
import liveMatchesSocket from './liveMatchesSocket';
import state from '@/store/state';

// import sentry from './sentry';
import offerLoadSequence from '@/utility/offerLoadSequence';
import api from '@/api';
import isMobile from '@/utility/isMobile';
import oddinEsport from './oddinESport';
import storage from '@/services/storage';

const configRetryTimeout = 5000;
let configRetryCount = 10;
let retryTimeoutInstance = null;

let gateway;
let storeDispatch;
let routeParams;
let localCompanyConfig;
let handleUrlParams;

const appLoad = ({ data }) => {
  gateway.sendMessage({ action: 'Slave.Loaded', data: {} });

  clearTimeout(retryTimeoutInstance);
  const { companyName, sourceCompanyName } = routeParams;
  const product = 'prematch';
  const cpvUuid = (data.product || {}).cpvUuid
    || (appConfig[companyName] || {}).cpvUuid;
  const currency = data.currency?.symbol;
  const localTimezone = moment.tz.guess();

  storeDispatch('updateSettings', _.merge(data, { timezone: localTimezone }));
  storeDispatch('setSourceCompanyName', sourceCompanyName);

  if (routeParams.ticket) {
    storeDispatch('checkCode',
      { ticketCode: routeParams.ticket, global: true });
  }

  if ((data.user || {}).balance) {
    storeDispatch('updateUserBalance', data.user.balance);
  }

  const userId = (data.user || {}).id || '';
  socket.instance(data.company.id, storeDispatch, userId, routeParams);

  Promise.all([
    api.getCompanyAppSettings(companyName, product),
    api.getWebAppConfig(data.company),
  ]).then(([{ appSettings }, gatewayConfig]) => {
    const { config: companyAppSettings } = appSettings;
    const companyConfig = _.assign(
      {},
      localCompanyConfig,
      companyAppSettings,
      gatewayConfig,
    );
    handleUrlParams = companyConfig.updateMasterUrlOnRouteChange;
    const gTagID = ((companyConfig.googleTagManagerContainerID || {})[serverEnv]) || '';

    companyConfig.liveCpvUuid = companyConfig.liveCpvUuid || localCompanyConfig.cpvUuid;

    if (companyConfig?.filterTimeOptions?.link?.active) {
      liveMatchesSocket.instance({
        dispatch: storeDispatch,
        cpvUuid: companyConfig?.liveCpvUuid,
        language: data.language || 'en',
      });
    }

    if (cpvUuid) companyConfig.cpvUuid = cpvUuid;

    storeDispatch('updateConfig', companyConfig);
    storeDispatch('setProcessMode', { mode: activeEnv });
    storeDispatch('updateBetslipSettings', { config: companyConfig });
    storeDispatch('setDefaultTimesValues', !!routeParams.sport || !!routeParams.topOffer);


    const gridMode = isMobile
      ? storage.getItem('sortOption', 'sessionStorage') ?? companyConfig.defaultGridMode
      : companyConfig.defaultGridMode;

    storeDispatch('setGridMode', gridMode);
    if (userId) {
      storeDispatch('loadLocalUserFavorites');
    }
    offerLoadSequence(storeDispatch, routeParams, handleUrlParams);
    if (companyConfig.mobSidebarDisplayedOnLoad) storeDispatch('toggleMobileSportFilter', true);

    if (companyConfig.cashoutEnabled) {
      storeDispatch('initCashoutSocket');
      storeDispatch('loadCashoutTickets').then(() => storeDispatch('subscibeCashoutTickets'));
    }

    if (companyConfig?.oddinESport?.enabled) {
      const oddinLang = data.language === 'sr-Latn' || data.language === 'sr_Latn'
        ? 'sr'
        : data.language ?? 'en';

      storeDispatch('getOddinMatchesCount');
      storeDispatch('getOddinESportsConfig', {
        token: data.auth?.token,
        language: oddinLang,
        currency,
      }).then(() => {
        if (routeParams.sport === '9997-oddin') {
          storeDispatch('setOddin', true);
        }
      });
    }

    if (companyConfig?.embeddedResults?.enabled) {
      if (routeParams.section === 'services' && routeParams.sport === 'results') {
        storeDispatch('setEmbeddedResultsViewStatus', { active: true });
        storeDispatch('emitRouteChangeToEmbeddedResults');
      }
    }

    if (companyConfig?.xtremeNotifications?.enabled) {
      storeDispatch('getXtremePushPlayerStatus');
    }

    // sentry.init(data);
    const googleAnalyticsId = data.settings?.analytics?.googleAnalytics;

    if (googleAnalyticsId) initGA4(googleAnalyticsId);
    attachGoogleTagManager(gTagID);
    storeDispatch('loadTranslations');
    storeDispatch('handleSIRConnect');
  }).catch((error) => {
    if (!configRetryCount) {
      console.log('Error in fetching config. ', error);
      return;
    }
    retryTimeoutInstance = setTimeout(() => {
      appLoad({ data, product });
    }, configRetryTimeout);
    configRetryCount -= 1;
  });
};

const setListeners = () => {
  gateway.on('User.BalanceChanged', ({ data }) => {
    storeDispatch('updateUserBalance', data.balance);
  });
  gateway.on('Widget.ClickedOutside', () => {
    storeDispatch('closeDropdown');
    storeDispatch('closeMobileWidgets');
    storeDispatch('closeMobileBetslip');
  });
  gateway.on('User.AuthorizationChanged', ({ data }) => {
    const { auth } = data;
    const { user, token, tpToken } = auth;
    const userId = user && user.id ? user.id : '';
    if (userId !== state.settings.user.id) socket.reconnectStatusSocket(userId);
    storeDispatch('updateUser', user);
    if (user.logged) {
      storeDispatch('updateUserToken', { token, tpToken }).then(() => {
        // const { dc } = localCompanyConfig;
        // storeDispatch('loadUserFavorites', dc);
        storeDispatch('getOddinESportsAuth', {
          tpToken,
          token,
          language: user?.profile?.language || 'en',
          currency: user?.currency || '',
        });
      });
      storeDispatch('initCashoutSocket');
      storeDispatch('loadLatestTickets');
      storeDispatch('getPlayerSettings');
      storeDispatch('loadCashoutTickets').then(() => storeDispatch('subscibeCashoutTickets'));
      storeDispatch('getXtremePushPlayerStatus');
    } else {
      storeDispatch('clearTicketsHistory');
      storeDispatch('loadLocalUserFavorites');
      storeDispatch('removeAllNotifications');
      storeDispatch('setSelectedTab', 'betslip');
      storeDispatch('updateOddinESportsData', { token: '' });
    }
  });
  gateway.on('User.ProfileChanged', () => {});
  gateway.on('Widget.ToggleBetslip', () => {});
  gateway.on('UI.Show', ({ data }) => storeDispatch('handleShowUI', { name: data.name, isGatewayMessage: true }));
  gateway.on('UI.Hide', ({ data }) => storeDispatch('handleHideUI', data));
  gateway.on('Notification.Clicked', ({ data = {} }) => {
    storeDispatch('globalNotificationAction', data);
  });
  gateway.on('Notification.Expired', ({ data = {} }) => {
    storeDispatch('handleNotificationExpired', data);
  });
  gateway.on('Router.RouteChanged', ({ data: { params } }) => {
    if (!params.section) return;
    storeDispatch('setDefaultTimesValues', true);
    offerLoadSequence(storeDispatch, params, handleUrlParams);
  });
};

export default {
  init({ dispatch, params }) {
    storeDispatch = dispatch;
    routeParams = params;

    oddinEsport.init(storeDispatch);

    if (routeParams.companyName) {
      storeDispatch('setCompanyName', routeParams.companyName);
      localCompanyConfig = _.assign(appConfig.default, appConfig[routeParams.companyName]);
    }
    const gatewayConstructor = Gateway.slave;
    gateway = gatewayConstructor({
      debug: true,
      allowedOrigins: '*',
      slaveId: 'PluginSMGatewayPrematch',
      data: {
        title: 'SM prematch client',
      },
      eventPropagation: {
        click: true,
      },
      load: data => appLoad(data),
    });
    setListeners();
    this.sendMessage('Tickets.Changed', { ticket: { betCount: 0 } });
  },
  sendMessage(action, data) {
    const gatewayDataConstruct = { action, data };
    if (gateway) gateway.sendMessage(gatewayDataConstruct);
  },
};
